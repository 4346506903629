<template>
    <TopicMB v-if="layout == 4" />
    <TopicPC v-else />
</template>

<script>
    import TopicMB from '@/pages/mb/Topic';
    import TopicPC from '@/pages/pc/Topic';
    import { ref , provide } from 'vue';
    import TagService from '@/services/tag';
    export default {
        components:{
            TopicMB,
            TopicPC
        },
        setup(){
            const groupTag = ref([]);
            const loading  = ref(true);
            getGroupTags();

            async function getGroupTags(){
                const response = await TagService.fetchGroupTagsWithPost();    
                loading.value  = false;    
                if(response.data){
                    groupTag.value = response.data.group_tags;
                }
            }

            provide('groupTag' , groupTag);
            provide('loading' ,loading);
        }
    }
</script>
